import React from 'react';

const getSurveyParam = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const surveyValue = urlParams.get('interview');

  if (surveyValue) {
    return surveyValue;
  } else {
    return '';
  }
};

export default getSurveyParam;
