import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BackendApi from '../apis/BackendApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import changeURL from '../global-functions/changeURL';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  KeyboardAvoidingView,
  Link,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const WelcomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [consent, setConsent] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [warn_consent, setWarn_consent] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const validateEmail = email => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const CheckArray = array_input => {
    if (Array.isArray(array_input) && array_input.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const backendCreateRespondentPOST = BackendApi.useCreateRespondentPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEmail('');
      setConsent(false);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.ScreenContainerStyles(theme)['Screen Layout'],
          { alignContent: 'space-around' }
        ),
        dimensions.width
      )}
    >
      <>
        {Constants['project_details'] &&
        Constants['project_details'][0].skip_welcome ? null : (
          <HeaderBlock />
        )}
      </>
      {/* Main frame */}
      <>
        {Constants['project_details'] &&
        Constants['project_details'][0].skip_welcome ? null : (
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['Main Frame Div'],
                { backgroundColor: Constants['colours']?.zbase }
              ),
              dimensions.width
            )}
          >
            {/* max width container */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['max width container'],
                  { maxWidth: { minWidth: Breakpoints.Tablet, value: 720 } }
                ),
                dimensions.width
              )}
            >
              <KeyboardAvoidingView
                behavior={'padding'}
                enabled={true}
                style={StyleSheet.applyWidth(
                  GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                    'keyboard avoiding view'
                  ],
                  dimensions.width
                )}
              >
                {/* Welcome Title */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['title'],
                      {
                        color:
                          Constants['project_details'] &&
                          Constants['project_details'][0].colour,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Constants['project_details'] &&
                    Constants['project_details'][0].welcome_title}{' '}
                </Text>
                {/* Welcome Message */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['body'], {
                      color: Constants['colours']?.zcontrast,
                    }),
                    dimensions.width
                  )}
                >
                  {Constants['project_details'] &&
                    Constants['project_details'][0].welcome_message}
                </Text>
                {/* Welcome Second Title */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['title'],
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors['! Disabled Text'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: Constants['colours']?.disabledtext,
                          },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {Constants['project_details'] &&
                    Constants['project_details'][0].welcome_second_title}
                </Text>
                {/* Welcome Second Message */}
                <Text
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['body'], {
                      color: Constants['colours']?.zcontrast,
                    }),
                    dimensions.width
                  )}
                >
                  {Constants['project_details'] &&
                    Constants['project_details'][0].welcome_second_message}
                </Text>
                {/* Email Title */}
                <>
                  {!(
                    Constants['project_details'] &&
                    Constants['project_details'][0].collect_email
                  ) ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['title'],
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['! Disabled Text'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: Constants['colours']?.disabledtext,
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {Constants['project_details'] &&
                        Constants['project_details'][0].email_title}
                    </Text>
                  )}
                </>
                {/* Email Input */}
                <>
                  {!(
                    Constants['project_details'] &&
                    Constants['project_details'][0].collect_email
                  ) ? null : (
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      clearButtonMode={'always'}
                      clearTextOnFocus={false}
                      enablesReturnKeyAutomatically={true}
                      keyboardType={'email-address'}
                      numberOfLines={1}
                      onChangeText={newEmailInputValue => {
                        try {
                          setEmail(newEmailInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={(
                        Constants['project_details'] &&
                        Constants['project_details'][0].email_placeholder
                      ).toString()}
                      placeholderTextColor={theme.colors['! Disabled Text']}
                      spellcheck={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Input Field'],
                          {
                            backgroundColor: Constants['colours']?.inputbckg,
                            borderColor: Constants['colours']?.hrline,
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors['zContrast'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: Constants['colours']?.zcontrast,
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      value={email}
                    />
                  )}
                </>
                {/* Consent */}
                <>
                  {!(
                    Constants['project_details'] &&
                    Constants['project_details'][0].consent
                  ) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row', marginTop: 12 },
                        dimensions.width
                      )}
                    >
                      {/* consent_warning */}
                      <>
                        {!warn_consent ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors['Error'],
                                height: 2,
                                marginTop: -8,
                                position: 'absolute',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <Checkbox
                        color={theme.colors['Custom Color']}
                        onCheck={() => {
                          const checkboxValue = undefined;
                          try {
                            if (
                              !(
                                Constants['project_details'] &&
                                Constants['project_details'][0].collect_email
                              )
                            ) {
                              setEmail('no@email.com');
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onPress={newCheckboxValue => {
                          const checkboxValue = newCheckboxValue;
                          try {
                            setConsent(newCheckboxValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        status={consent}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.CheckboxStyles(theme)['checkbox'],
                            { marginRight: 12 }
                          ),
                          dimensions.width
                        )}
                        uncheckedColor={theme.colors['zContrast']}
                      />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['body'],
                            {
                              color: Constants['colours']?.zcontrast,
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 10 },
                                { minWidth: Breakpoints.Tablet, value: 12 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Constants['project_details'] &&
                          Constants['project_details'][0].consent}
                        <Link
                          onPress={() => {
                            try {
                              changeURL(
                                Constants['project_details'] &&
                                  Constants['project_details'][0].consent_link
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinkStyles(theme)['Link'],
                              {
                                color:
                                  Constants['project_details'] &&
                                  Constants['project_details'][0].colour,
                              }
                            ),
                            dimensions.width
                          )}
                          title={` ${
                            Constants['project_details'] &&
                            Constants['project_details'][0].consent_link
                          }`}
                        />
                      </Text>
                    </View>
                  )}
                </>
              </KeyboardAvoidingView>
              {/* Footer */}
              <>
                {!(Constants['project_details'] !== '') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['Footer Div'],
                        {
                          alignItems: {
                            minWidth: Breakpoints.Tablet,
                            value: 'baseline',
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* CTA Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignSelf: 'flex-end' },
                        dimensions.width
                      )}
                    >
                      {/* Next */}
                      <Button
                        disabled={!validateEmail(email)}
                        disabledOpacity={0.4}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (consent === false) {
                                setWarn_consent(true);
                              }
                              if (!consent) {
                                return;
                              }
                              if (
                                !(
                                  Constants['project_details'] &&
                                  Constants['project_details'][0].collect_email
                                )
                              ) {
                                setEmail('Excluded');
                              }
                              const CreatedRespondent = (
                                await backendCreateRespondentPOST.mutateAsync({
                                  consent:
                                    Constants['project_details'] &&
                                    Constants['project_details'][0].consent,
                                  email: email,
                                })
                              )?.json;
                              setGlobalVariableValue({
                                key: 'user_id',
                                value: CreatedRespondent?.uuid,
                              });
                              if (CreatedRespondent?.uuid !== '') {
                                navigation.navigate('TopicScreen');
                              }
                              console.log(CreatedRespondent, 'login response');
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button CTA'],
                            {
                              backgroundColor: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: theme.colors['zAccent'],
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value:
                                    Constants['project_details'] &&
                                    Constants['project_details'][0].colour,
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                        title={`${
                          Constants['project_details'] &&
                          Constants['project_details'][0].cta_next
                        }`}
                      />
                    </View>
                  </View>
                )}
              </>
            </View>
          </View>
        )}
      </>
      {/* ProjectDetails */}
      <BackendApi.FetchProjectGET
        handlers={{
          on2xx: projectDetailsData => {
            const handler = async () => {
              try {
                const project_fetched2 = setGlobalVariableValue({
                  key: 'project_details',
                  value: JSON.parse(projectDetailsData?.text),
                });
                if (
                  (project_fetched2 && project_fetched2[0].dark_mode) !==
                  (props.route?.params?.TRUE ?? null)
                ) {
                  setGlobalVariableValue({
                    key: 'colours',
                    value: '',
                  });
                }
                if (
                  (project_fetched2 && project_fetched2[0].dark_mode) ===
                  (props.route?.params?.TRUE ?? null)
                ) {
                  setGlobalVariableValue({
                    key: 'colours',
                    value: Constants['darkmode_scheme'],
                  });
                }
                if (Constants['user_id'] === '') {
                  if (
                    (project_fetched2 && project_fetched2[0].skip_welcome) !==
                    true
                  ) {
                    return;
                  }
                  const createdUser = (
                    await backendCreateRespondentPOST.mutateAsync({
                      consent: '',
                      email: 'no@email.com',
                    })
                  )?.json;
                  const createdUuid = setGlobalVariableValue({
                    key: 'user_id',
                    value: createdUser?.uuid,
                  });
                  if (createdUuid !== '') {
                    navigation.navigate('TopicScreen');
                  }
                } else {
                  if (Constants['user_id'] !== '') {
                    navigation.navigate('TopicScreen');
                  }
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          },
          on4xx: projectDetailsData => {
            try {
              navigation.navigate('ErrorScreen', {
                error_type: 'Wrong Interview ID',
              });
              navigation.navigate('ErrorScreen', {
                error_type: 'Interview ID missing',
              });
            } catch (err) {
              console.error(err);
            }
          },
          on5xx: projectDetailsData => {
            try {
              navigation.navigate('ErrorScreen', {
                error_type: 'Wrong Interview ID',
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchProject }) => {
          const projectDetailsData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </BackendApi.FetchProjectGET>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);
