import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getExternalId from '../global-functions/getExternalId';
import getFirstAnswer from '../global-functions/getFirstAnswer';
import getSurveyParam from '../global-functions/getSurveyParam';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { SVG, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';

const IndexRedirectScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'question',
        value: '',
      });
      setGlobalVariableValue({
        key: 'project_details',
        value: '',
      });
      if (getSurveyParam() !== '') {
        const survey = getSurveyParam();
        const FirstAnswer = getFirstAnswer();
        const externalId = getExternalId();
        if (externalId !== Constants['externalID']) {
          setGlobalVariableValue({
            key: 'user_id',
            value: '',
          });
        } else {
          if (Constants['project'] !== survey) {
            setGlobalVariableValue({
              key: 'user_id',
              value: '',
            });
          } else {
          }
        }

        setGlobalVariableValue({
          key: 'first_answer',
          value: FirstAnswer,
        });
        setGlobalVariableValue({
          key: 'externalID',
          value: externalId,
        });
        setGlobalVariableValue({
          key: 'project',
          value: survey,
        });
        navigation.navigate('WelcomeScreen');
      } else {
        navigation.navigate('ErrorScreen', {
          error_type: 'Interview ID is missing',
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { alignItems: 'center', justifyContent: 'center' },
        dimensions.width
      )}
    >
      <SVG
        source={
          'https://lwwyepvurqddbcbggdvm.supabase.co/storage/v1/object/public/files/Ellipsis-3.7s-200px.svg'
        }
        style={StyleSheet.applyWidth(
          GlobalStyles.SVGStyles(theme)['SVG'],
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(IndexRedirectScreen);
