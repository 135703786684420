import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BackendApi from '../apis/BackendApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  KeyboardAvoidingView,
  Link,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Keyboard, Text, View } from 'react-native';

const TopicScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cta_disable, setCta_disable] = React.useState(true);
  const [input, setInput] = React.useState('');
  const [input_disable, setInput_disable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const calculateMinutesPassed = timestamp => {
    // Parse the timestamp into a Date object
    const pastDate = new Date(timestamp);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - pastDate;

    // Convert milliseconds to minutes and round down
    // Note: 1 minute = 60,000 milliseconds
    const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

    return diffInMinutes;
  };

  const getProjectTopic = (timestamp, projectTopics) => {
    // Parse the timestamp into a Date object
    const pastDate = new Date(timestamp);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - pastDate;

    // Convert milliseconds to minutes and round down
    const minPassed = Math.floor(diffInMs / (60 * 1000));

    // Loop through the projectTopics array
    for (let i = 0; i < projectTopics.length; i++) {
      // If quit_after of the current project topic is greater than minPassed
      if (projectTopics[i].quit_after > minPassed) {
        // Return the project topic
        return projectTopics[i];
      }
    }

    // If no project topic was found that satisfies the condition, return "exit"
    return 'exit';
  };

  const waitQuestionsStored = async Variables => {
    try {
      // ensure first POST request is completed
      await researchDBSaveQuestion$AnswerPOST.mutateAsync({
        message: Constants['question'],
        proejct_id: Constants['project'],
        role: 'assistant',
        topicID: topic_id,
        user_id: Constants['user_id'],
      });

      // ensure second POST request is completed
      await researchDBSaveQuestion$AnswerPOST.mutateAsync({
        message: input,
        proejct_id: Constants['project'],
        role: 'user',
        topicID: topic_id,
        user_id: Constants['user_id'],
      });

      // only after both POST requests have completed, initiate refetch
      await refetchHistory();
    } catch (err) {
      console.error(err);
    }
  };
  const backendStartInterviewGET = BackendApi.useStartInterviewGET();
  const backendReplyPOST = BackendApi.useReplyPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      console.log('Screen ON_SCREEN_FOCUS Start');
      let error = null;
      try {
        if (!isFocused) {
          return;
        }
        console.log('Start ON_SCREEN_FOCUS:0 NAVIGATE');
        if (Constants['user_id'] === '') {
          navigation.navigate('WelcomeScreen');
          console.log('Complete ON_SCREEN_FOCUS:0 NAVIGATE');
        } else {
          console.log('Skipped ON_SCREEN_FOCUS:0 NAVIGATE: condition not met');
        }
        console.log('Start ON_SCREEN_FOCUS:1 FETCH_REQUEST');
        const RespondQuestion = (
          await backendStartInterviewGET.mutateAsync({
            first_answer: Constants['first_answer'],
          })
        )?.json;
        console.log('Complete ON_SCREEN_FOCUS:1 FETCH_REQUEST', {
          RespondQuestion,
        });
        console.log('Start ON_SCREEN_FOCUS:2 NAVIGATE');
        if (RespondQuestion?.status === 'closed') {
          navigation.navigate('SuccessScreen');
          console.log('Complete ON_SCREEN_FOCUS:2 NAVIGATE');
        } else {
          console.log('Skipped ON_SCREEN_FOCUS:2 NAVIGATE: condition not met');
        }
        console.log('Start ON_SCREEN_FOCUS:3 SET_VARIABLE');
        setGlobalVariableValue({
          key: 'question',
          value: RespondQuestion?.response,
        });
        console.log('Complete ON_SCREEN_FOCUS:3 SET_VARIABLE');
      } catch (err) {
        console.error(err);
        error = err.message ?? err;
      }
      console.log(
        'Screen ON_SCREEN_FOCUS Complete',
        error ? { error } : 'no error'
      );
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(
          GlobalStyles.ScreenContainerStyles(theme)['Screen Layout'],
          { backgroundColor: theme.colors['! WHITE'] }
        ),
        dimensions.width
      )}
    >
      <>
        {Constants['project_details'] &&
        Constants['project_details'][0].skip_welcome ? null : (
          <HeaderBlock />
        )}
      </>
      {/* Main Frame + Fetch */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Main Frame Div'], {
            backgroundColor: Constants['colours']?.zbase,
          }),
          dimensions.width
        )}
      >
        {/* max width container */}
        <View
          style={StyleSheet.applyWidth(
            GlobalStyles.ViewStyles(theme)['max width container'],
            dimensions.width
          )}
        >
          {/* Question and Answer */}
          <View
            style={StyleSheet.applyWidth(
              {
                paddingBottom: 32,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 32,
              },
              dimensions.width
            )}
          >
            {/* Question 2 */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['question div'],
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['zBase'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: Constants['colours']?.zbase,
                      },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* question title */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['title'], {
                    color:
                      Constants['project_details'] &&
                      Constants['project_details'][0].colour,
                  }),
                  dimensions.width
                )}
              >
                {Constants['project_details'] &&
                  Constants['project_details'][0].question_title}
              </Text>
              {/* question */}
              <>
                {!Constants['question'] ? null : (
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['body'],
                        { color: Constants['colours']?.zcontrast }
                      ),
                      dimensions.width
                    )}
                  >
                    {Constants['question']}
                  </Text>
                )}
              </>
              {/* Loading */}
              <>
                {Constants['question'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* loading */}
                    <Text
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['body'],
                          { color: Constants['colours']?.zcontrast }
                        ),
                        dimensions.width
                      )}
                    >
                      {Constants['project_details'] &&
                        Constants['project_details'][0].loading}
                    </Text>
                  </View>
                )}
              </>
            </View>
            {/* Content 2 */}
            <View>
              {/* title */}
              <Text
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['title'], {
                    color: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['! Disabled Text'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: Constants['colours']?.disabledtext,
                      },
                    ],
                  }),
                  dimensions.width
                )}
              >
                {Constants['project_details'] &&
                  Constants['project_details'][0].answer_title}
              </Text>
              {/* Answer Input */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                autoFocus={true}
                changeTextDelay={500}
                clearButtonMode={'never'}
                clearTextOnFocus={false}
                disabled={Constants['question'] === ''}
                enablesReturnKeyAutomatically={true}
                keyboardAppearance={'dark'}
                keyboardType={'default'}
                multiline={true}
                onChangeText={newAnswerInputValue => {
                  try {
                    setInput(newAnswerInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onSubmitEditing={() => {
                  try {
                    Keyboard.dismiss();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={(
                  Constants['project_details'] &&
                  Constants['project_details'][0].answer_placeholder
                ).toString()}
                placeholderTextColor={theme.colors['! Disabled Text']}
                returnKeyType={'done'}
                spellcheck={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Answer Input'],
                    {
                      backgroundColor: Constants['colours']?.inputbckg,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['zContrast'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: Constants['colours']?.zcontrast,
                        },
                      ],
                      height: 120,
                    }
                  ),
                  dimensions.width
                )}
                value={input}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['! Disabled Text'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: Constants['colours']?.disabledtext,
                      },
                    ],
                    fontSize: 10,
                    marginTop: 16,
                  }),
                  dimensions.width
                )}
              >
                {Constants['project_details'] &&
                  Constants['project_details'][0].inline_consent}
              </Text>
            </View>
          </View>
          {/* Footer with fetch */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Footer Div'],
              dimensions.width
            )}
          >
            {/* CTA Area */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Abort */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors['! Disabled Text'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: Constants['colours']?.disabledtext,
                      },
                    ],
                    borderStyle: 'dotted',
                    paddingBottom: 4,
                  },
                  dimensions.width
                )}
              >
                {/* CTA Abort */}
                <Link
                  onPress={() => {
                    try {
                      setInput('');
                      setGlobalVariableValue({
                        key: 'user_id',
                        value: '',
                      });
                      navigation.navigate('SuccessScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['! Disabled Text'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: Constants['colours']?.disabledtext,
                        },
                      ],
                      fontFamily: 'Inter_600SemiBold',
                      fontSize: 10,
                      textTransform: 'uppercase',
                    }),
                    dimensions.width
                  )}
                  title={`${
                    Constants['project_details'] &&
                    Constants['project_details'][0].cta_abort
                  }`}
                />
              </View>
              {/* Reply */}
              <Button
                disabled={input === ''}
                disabledOpacity={0.3}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const answer = input;
                      setInput('');
                      setGlobalVariableValue({
                        key: 'question',
                        value: '',
                      });
                      const ReplyQuetion = (
                        await backendReplyPOST.mutateAsync({ message: answer })
                      )?.json;
                      if (ReplyQuetion?.status === 'closed') {
                        navigation.navigate('SuccessScreen');
                      }
                      setGlobalVariableValue({
                        key: 'question',
                        value: ReplyQuetion?.response,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button CTA'],
                    {
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['zAccent'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value:
                            Constants['project_details'] &&
                            Constants['project_details'][0].colour,
                        },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={`${
                  Constants['project_details'] &&
                  Constants['project_details'][0].cta_reply
                }`}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TopicScreen);
