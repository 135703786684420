import React from 'react';

const getExternalId = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const externalId = urlParams.get('external_id');

  if (externalId) {
    return externalId;
  } else {
    return '';
  }
};

export default getExternalId;
