import React from 'react';

const getFirstAnswer = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const firstAnswer = urlParams.get('first_answer');

  if (firstAnswer) {
    return firstAnswer;
  } else {
    return '';
  }
};

export default getFirstAnswer;
