import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const createRespondentPOST = (
  Constants,
  { consent, email },
  handlers = {}
) =>
  fetch(`https://app.qvantify.com/api/respondent/`, {
    body: JSON.stringify({ email: email, consent: consent }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      externalId: Constants['externalID'],
      projectId: Constants['project'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateRespondentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createRespondentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchCreateRespondentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  consent,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateRespondentPOST(
    { consent, email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateRespondent: refetch });
};

export const projectGET = (Constants, _args, handlers = {}) =>
  fetch(`https://app.qvantify.com/api/project/`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      projectId: Constants['project'],
    },
  }).then(res => handleResponse(res, handlers));

export const useProjectGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['project', args],
    () => projectGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['projects']),
    }
  );
};

export const FetchProjectGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useProjectGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProject: refetch });
};

export const replyPOST = (Constants, { message }, handlers = {}) =>
  fetch(`https://app.qvantify.com/api/reply/`, {
    body: JSON.stringify({ message: message }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      projectId: Constants['project'],
      uuid: Constants['user_id'],
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useReplyPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => replyPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('answer', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('answer');
        queryClient.invalidateQueries('answers');
      },
    }
  );
};

export const FetchReplyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  message,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useReplyPOST(
    { message },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReply: refetch });
};

export const startInterviewGET = (Constants, { first_answer }, handlers = {}) =>
  fetch(
    `https://app.qvantify.com/api/interview/?first_answer=${encodeQueryParam(
      `${
        typeof first_answer === 'string'
          ? first_answer
          : JSON.stringify(first_answer ?? '')
      }`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        projectId: Constants['project'],
        uuid: Constants['user_id'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useStartInterviewGET = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => startInterviewGET(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('interview', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('interview');
        queryClient.invalidateQueries('interviews');
      },
    }
  );
};

export const FetchStartInterviewGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  first_answer,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStartInterviewGET(
    { first_answer },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStartInterview: refetch });
};
