import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import changeURL from '../global-functions/changeURL';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  KeyboardAvoidingView,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const ErrorScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const GenerateUserID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        GlobalStyles.ScreenContainerStyles(theme)['Screen Layout'],
        dimensions.width
      )}
    >
      <HeaderBlock />
      {/* Main Frame (Welcome) */}
      <View
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Main Frame Div'],
          dimensions.width
        )}
      >
        {/* max width container */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['max width container'],
              { maxWidth: { minWidth: Breakpoints.Tablet, value: 720 } }
            ),
            dimensions.width
          )}
        >
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'keyboard avoiding view'
              ],
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['! HR Line']}
              name={'Ionicons/ios-information-circle-outline'}
              size={56}
              style={StyleSheet.applyWidth(
                GlobalStyles.IconStyles(theme)['icon'],
                dimensions.width
              )}
            />
            {/* Success Title */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['title'],
                dimensions.width
              )}
            >
              {props.route?.params?.error_type ?? 'Error'}
            </Text>
            {/* Suceess Message */}
            <Text
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body'],
                dimensions.width
              )}
            >
              {
                "To join the interview, you'll need a valid URL that includes the interview ID for the active project. If you encounter any issues, please contact the organizer for assistance."
              }
            </Text>
          </KeyboardAvoidingView>
          {/* Footer */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Footer Div'],
              dimensions.width
            )}
          >
            {/* CTA Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'flex-end', flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* Visit */}
              <Button
                disabledOpacity={0.3}
                onPress={() => {
                  console.log('Visit ON_PRESS Start');
                  let error = null;
                  try {
                    console.log('Start ON_PRESS:0 CUSTOM_FUNCTION');
                    changeURL('https://www.qvantify.com');
                    console.log('Complete ON_PRESS:0 CUSTOM_FUNCTION');
                  } catch (err) {
                    console.error(err);
                    error = err.message ?? err;
                  }
                  console.log(
                    'Visit ON_PRESS Complete',
                    error ? { error } : 'no error'
                  );
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button CTA Secondary'],
                    {
                      backgroundColor: theme.colors['! WHITE'],
                      borderColor: theme.colors['zAccent'],
                      color: theme.colors['zAccent'],
                    }
                  ),
                  dimensions.width
                )}
                title={'Visit Qvantify'}
              />
              {/* Reload */}
              <Button
                disabledOpacity={0.3}
                onPress={() => {
                  console.log('Reload ON_PRESS Start');
                  let error = null;
                  try {
                    console.log('Start ON_PRESS:0 NAVIGATE');
                    navigation.navigate('WelcomeScreen');
                    console.log('Complete ON_PRESS:0 NAVIGATE');
                  } catch (err) {
                    console.error(err);
                    error = err.message ?? err;
                  }
                  console.log(
                    'Reload ON_PRESS Complete',
                    error ? { error } : 'no error'
                  );
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button CTA'],
                  dimensions.width
                )}
                title={'Try again'}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ErrorScreen);
