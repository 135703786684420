import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  KeyboardAvoidingView,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';

const SuccessScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const GenerateUserID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'question',
        value: '',
      });
      setGlobalVariableValue({
        key: 'user_id',
        value: '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        GlobalStyles.ScreenContainerStyles(theme)['Screen Layout'],
        dimensions.width
      )}
    >
      <>
        {Constants['project_details'] &&
        Constants['project_details'][0].skip_welcome ? null : (
          <HeaderBlock />
        )}
      </>
      {/* Main Frame (Welcome) */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Main Frame Div'], {
            backgroundColor: Constants['colours']?.zbase,
          }),
          dimensions.width
        )}
      >
        {/* max width container */}
        <View
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['max width container'],
              { maxWidth: { minWidth: Breakpoints.Tablet, value: 720 } }
            ),
            dimensions.width
          )}
        >
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'keyboard avoiding view'
              ],
              dimensions.width
            )}
          >
            <>
              {Constants['project_details'] &&
              Constants['project_details'][0].skip_welcome ? null : (
                <Icon
                  color={theme.colors['! HR Line']}
                  name={'Ionicons/checkmark-circle-outline'}
                  size={56}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icon'],
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* Success Title */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['title'], {
                  color:
                    Constants['project_details'] &&
                    Constants['project_details'][0].colour,
                }),
                dimensions.width
              )}
            >
              {Constants['project_details'] &&
                Constants['project_details'][0].success_title}
            </Text>
            {/* Suceess Message */}
            <Text
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['body'], {
                  color: Constants['colours']?.zcontrast,
                }),
                dimensions.width
              )}
            >
              {Constants['project_details'] &&
                Constants['project_details'][0].success_message}
            </Text>
          </KeyboardAvoidingView>
          {/* Footer */}
          <View
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Footer Div'],
              dimensions.width
            )}
          >
            {/* CTA Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'flex-end' },
                dimensions.width
              )}
            >
              {/* Reset Session Values */}
              <Button
                disabledOpacity={0.3}
                onPress={() => {
                  console.log('Reset Session Values ON_PRESS Start');
                  let error = null;
                  try {
                    console.log('Start ON_PRESS:0 NAVIGATE');
                    navigation.navigate('IndexRedirectScreen');
                    console.log('Complete ON_PRESS:0 NAVIGATE');
                  } catch (err) {
                    console.error(err);
                    error = err.message ?? err;
                  }
                  console.log(
                    'Reset Session Values ON_PRESS Complete',
                    error ? { error } : 'no error'
                  );
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button CTA'],
                    {
                      backgroundColor: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors['zAccent'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value:
                            Constants['project_details'] &&
                            Constants['project_details'][0].colour,
                        },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={`${
                  Constants['project_details'] &&
                  Constants['project_details'][0].cta_restart
                }`}
              />
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SuccessScreen);
