import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const CheckboxStyles = theme => StyleSheet.create({});

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Answer Input': {
      backgroundColor: theme.colors['! Input bckg'],
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      borderBottomWidth: 1,
      borderColor: theme.colors['! HR Line'],
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderTopWidth: 1,
      fontFamily: 'Inter_400Regular',
      marginTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    'Input Field': {
      backgroundColor: theme.colors['! Input bckg'],
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      borderBottomWidth: 1,
      borderColor: theme.colors['! HR Line'],
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderTopWidth: 1,
      fontFamily: 'Inter_400Regular',
      fontSize: { minWidth: Breakpoints.Tablet, value: 16 },
      marginBottom: 8,
      marginTop: 8,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
    },
    'Text Input': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    'Button CTA': {
      backgroundColor: theme.colors['! Title'],
      borderRadius: 8,
      fontFamily: 'Inter_700Bold',
      fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
      marginLeft: 12,
      textAlign: 'center',
    },
    'Button CTA Secondary': {
      backgroundColor: theme.colors['! Title'],
      borderRadius: 8,
      fontFamily: 'Inter_700Bold',
      fontSize: { minWidth: Breakpoints.Laptop, value: 16 },
      marginLeft: 12,
      textAlign: 'center',
    },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({ 'Checkbox Row': { minHeight: 50 } });

export const KeyboardAwareScrollViewStyles = theme =>
  StyleSheet.create({
    'Content Div': {
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 32,
    },
    'Scrollable Content Div': {
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 32,
    },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const ImageStyles = theme =>
  StyleSheet.create({ Image: { height: 24, width: 110 } });

export const PressableStyles = theme =>
  StyleSheet.create({ Pressable: { width: 80 }, 'Pressable 2': { width: 80 } });

export const SVGStyles = theme =>
  StyleSheet.create({ SVG: { height: 100, width: 100 } });

export const ScreenContainerStyles = theme =>
  StyleSheet.create({
    'Screen Layout': {
      flexDirection: [
        { minWidth: Breakpoints.Tablet, value: 'row' },
        { minWidth: Breakpoints.Mobile, value: 'column' },
      ],
    },
  });

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { color: theme.colors.strong },
    'admin text': { color: theme.colors['Medium'], fontSize: 8 },
    body: {
      alignSelf: { minWidth: Breakpoints.Tablet, value: 'flex-start' },
      color: theme.colors['zContrast'],
      fontSize: { minWidth: Breakpoints.Tablet, value: 16 },
      marginBottom: { minWidth: Breakpoints.Laptop, value: 16 },
      paddingBottom: 16,
    },
    h1: { color: theme.colors.strong },
    h2: {
      color: theme.colors.strong,
      fontFamily: 'Inter_600SemiBold',
      fontSize: 20,
      paddingBottom: 8,
    },
    title: {
      alignSelf: { minWidth: Breakpoints.Tablet, value: 'flex-start' },
      color: theme.colors['zAccent'],
      fontFamily: 'Inter_600SemiBold',
      fontSize: [
        { minWidth: Breakpoints.Mobile, value: 10 },
        { minWidth: Breakpoints.Tablet, value: 14 },
      ],
      paddingBottom: 8,
      textTransform: 'uppercase',
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Footer Div': {
      alignItems: 'flex-end',
      borderColor: theme.colors['! HR Line'],
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
    },
    Header: {
      alignItems: 'center',
      backgroundColor: theme.colors['zBase'],
      borderBottomWidth: 1,
      borderColor: theme.colors['! HR Line'],
      borderRightWidth: { minWidth: Breakpoints.Tablet, value: 1 },
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 32,
    },
    'Main Frame Div': {
      alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
      backgroundColor: theme.colors['zBase'],
      flexGrow: [
        { minWidth: Breakpoints.Tablet, value: 1 },
        { minWidth: Breakpoints.Mobile, value: 1 },
      ],
      justifyContent: [
        { minWidth: Breakpoints.Tablet, value: 'center' },
        { minWidth: Breakpoints.Mobile, value: 'space-between' },
      ],
      width: { minWidth: Breakpoints.Tablet, value: '50%' },
    },
    'admin view': {
      backgroundColor: theme.colors['! WHITE'],
      marginLeft: 32,
      marginRight: 32,
      marginTop: 32,
    },
    'max width container': {
      height: '100%',
      justifyContent: [
        { minWidth: Breakpoints.Mobile, value: 'flex-start' },
        { minWidth: Breakpoints.Tablet, value: 'center' },
      ],
      maxWidth: { minWidth: Breakpoints.Tablet, value: 620 },
      minWidth: { minWidth: Breakpoints.Tablet, value: 620 },
    },
    'question div': {
      backgroundColor: theme.colors['! WHITE'],
      borderColor: theme.colors['! HR Line'],
      minHeight: 140,
      paddingBottom: 32,
    },
  });

export const IconStyles = theme =>
  StyleSheet.create({ icon: { marginBottom: 16 } });

export const KeyboardAvoidingViewStyles = theme =>
  StyleSheet.create({
    'keyboard avoiding view': {
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 32,
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({ Link: { color: theme.colors['zAccent'] } });
