import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { withTheme } from '@draftbit/ui';
import { Image, View } from 'react-native';

const HeaderBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const getProjectTopic = (timestamp, projectTopics) => {
    // Parse the timestamp into a Date object
    const pastDate = new Date(timestamp);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - pastDate;

    // Convert milliseconds to minutes and round down
    const minPassed = Math.floor(diffInMs / (60 * 1000));

    // Loop through the projectTopics array
    for (let i = 0; i < projectTopics.length; i++) {
      // If quit_after of the current project topic is greater than minPassed
      if (projectTopics[i].quit_after > minPassed) {
        // Return the project topic
        return projectTopics[i];
      }
    }

    // If no project topic was found that satisfies the condition, return "exit"
    return 'exit';
  };

  const calculateMinutesPassed = timestamp => {
    // Parse the timestamp into a Date object
    const pastDate = new Date(timestamp);

    // Get the current date
    const now = new Date();

    // Calculate the difference in milliseconds
    const diffInMs = now - pastDate;

    // Convert milliseconds to minutes and round down
    // Note: 1 minute = 60,000 milliseconds
    const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

    return diffInMinutes;
  };

  const waitQuestionsStored = async Variables => {
    try {
      // ensure first POST request is completed
      await researchDBSaveQuestion$AnswerPOST.mutateAsync({
        message: Constants['question'],
        proejct_id: Constants['project'],
        role: 'assistant',
        topicID: topic_id,
        user_id: Constants['user_id'],
      });

      // ensure second POST request is completed
      await researchDBSaveQuestion$AnswerPOST.mutateAsync({
        message: input,
        proejct_id: Constants['project'],
        role: 'user',
        topicID: topic_id,
        user_id: Constants['user_id'],
      });

      // only after both POST requests have completed, initiate refetch
      await refetchHistory();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Header'], {
          backgroundColor: Constants['colours']?.zbase,
          borderColor: Constants['colours']?.hrline,
        }),
        dimensions.width
      )}
    >
      <Image
        resizeMode={'contain'}
        source={{
          uri: `${
            Constants['project_details'] && Constants['project_details'][0].logo
          }`,
        }}
        style={StyleSheet.applyWidth(
          GlobalStyles.ImageStyles(theme)['Image'],
          dimensions.width
        )}
      />
    </View>
  );
};

export default withTheme(HeaderBlock);
