import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import getExternalId from './global-functions/getExternalId';
import getFirstAnswer from './global-functions/getFirstAnswer';
import getSurveyParam from './global-functions/getSurveyParam';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import ErrorScreen from './screens/ErrorScreen';
import IndexRedirectScreen from './screens/IndexRedirectScreen';
import SuccessScreen from './screens/SuccessScreen';
import TopicScreen from './screens/TopicScreen';
import WelcomeScreen from './screens/WelcomeScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FBFCFD',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="IndexRedirectScreen"
        screenOptions={({ navigation }) => ({ headerShown: false })}
      >
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={({ navigation }) => ({
            title: 'Welcome',
          })}
        />
        <Stack.Screen
          name="TopicScreen"
          component={TopicScreen}
          options={({ navigation }) => ({
            title: 'Topic',
          })}
        />
        <Stack.Screen
          name="IndexRedirectScreen"
          component={IndexRedirectScreen}
          options={({ navigation }) => ({
            title: 'Index - Redirect',
          })}
        />
        <Stack.Screen
          name="ErrorScreen"
          component={ErrorScreen}
          options={({ navigation }) => ({
            title: 'Error',
          })}
        />
        <Stack.Screen
          name="SuccessScreen"
          component={SuccessScreen}
          options={({ navigation }) => ({
            title: 'Success',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
